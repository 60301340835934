import { useState, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { fetchSchoolsPerState, fetchSchoolsCount } from './ApiHandler/schoolFunctions';
import { fetchLabsCount } from './ApiHandler/labFunctions';
import { fetchSessionsPerMonth, fetchSessionsCount } from './ApiHandler/sessionFunctions';
import { fetchLabEquipmentCount, fetchEquipmentCount, FetchLabsWithMostAllocatedEquipment, FetchSchoolsWithMostAllocatedEquipment } from './ApiHandler/equipmentFunctions';
import { fetchArtifactsCounts, fetchTopContributors } from './ApiHandler/artifactsFunctions';
import { fetchSearchesCounts, fetchTopSearchedTags } from './ApiHandler/tagsFunctions';
import { fetchAllocatedUsedSpace } from './ApiHandler/settingsFunctions';

const Home = () => {
    const [totalDocsCount, setTotalDocsCount] = useState(0);
    const [totalUrlsCount, setTotalUrlsCount] = useState(0);
    const [totalSearches, setTotalSearches] = useState(0);
    const [currentMonthSearches, setCurrentMonthSearches] = useState(0);
    const [usedSpace, setUsedSpace] = useState(0);
    const [totalAllocatedSpace, setTotalAllocatedSpace] = useState(0);
    const [searchedTags, setSearchedTags] = useState([]);
    const [contributors, setContributors] = useState([]);
    const [schoolsData, setSchoolsData] = useState([]);
    const [sessionData, setSessionData] = useState([]);
    const [labEquipmentData, setLabEquipmentData] = useState([]);
    const [totalSchoolsCount, setTotalSchoolsCount] = useState(0);
    const [totalLabsCount, setTotalLabsCount] = useState(0);
    const [totalEquipmentCount, setTotalEquipmentCount] = useState(0);
    const [totalSessionsCount, setTotalSessionsCount] = useState(0);
    const [labsWithMostAllocatedEquipment, setLabsWithMostAllocatedEquipment] = useState([]);
    const [schoolsWithMostAllocatedEquipment, setSchoolsWithMostAllocatedEquipment] = useState([]);

    useEffect(() => {
        fetchArtifactsCounts(setTotalDocsCount, setTotalUrlsCount);
        fetchSearchesCounts(setTotalSearches, setCurrentMonthSearches);
        fetchAllocatedUsedSpace(setTotalAllocatedSpace, setUsedSpace, null, null, null);
        fetchTopSearchedTags(setSearchedTags);
        fetchTopContributors(setContributors);
        fetchSchoolsPerState(setSchoolsData);
        fetchSessionsPerMonth(setSessionData);
        fetchLabEquipmentCount(setLabEquipmentData);
        fetchSchoolsCount(setTotalSchoolsCount);
        fetchLabsCount(setTotalLabsCount);
        fetchEquipmentCount(setTotalEquipmentCount);
        fetchSessionsCount(setTotalSessionsCount);
        FetchLabsWithMostAllocatedEquipment(setLabsWithMostAllocatedEquipment);
        FetchSchoolsWithMostAllocatedEquipment(setSchoolsWithMostAllocatedEquipment);
    }, []);

    const usedSpacePercentage = totalAllocatedSpace > 0 ? (usedSpace / totalAllocatedSpace) * 100 : 0;

    const barColors = [
        "#8884d8", "#82ca9d", "#ff7300", "#d0ed57", "#ff6666", "#a4de6c", "#d84315",
        "#1e88e5", "#8e24aa", "#ffb74d", "#4caf50", "#ff5252", "#66bb6a", "#ef5350",
        "#ab47bc", "#5c6bc0", "#ffa726", "#26a69a", "#ec407a", "#8d6e63", "#ffeb3b",
        "#ff9800", "#ffcc80", "#cddc39", "#009688", "#03a9f4", "#ff4081", "#00bcd4"
    ];

    const stateAbbreviations = {
        'Andhra Pradesh': 'AP',
        'Arunachal': 'AR',
        'Assam': 'AS',
        'Bihar': 'BR',
        'Chhattisgarh': 'CG',
        'Goa': 'GA',
        'Gujrat': 'GJ',
        'Haryana': 'HR',
        'Himachal Pradesh': 'HP',
        'Jharkhand': 'JH',
        'Karnataka': 'KA',
        'Kerala': 'KL',
        'Madhya Pradesh': 'MP',
        'Maharashtra': 'MH',
        'Manipur': 'MN',
        'Meghalaya': 'ML',
        'Mizoram': 'MZ',
        'Nagaland': 'NL',
        'Odisha': 'OD',
        'Punjab': 'PB',
        'Rajasthan': 'RJ',
        'Sikkim': 'SK',
        'Tamil Nadu': 'TN',
        'Telangana': 'TS',
        'Tripura': 'TR',
        'Uttar Pradesh': 'UP',
        'Uttarakhand': 'UK',
        'West Bengal': 'WB',
        'Andaman and Nicobar Islands': 'ANI',
        'Chandigarh': 'CH',
        'Dadra and Nagar Haveli and Daman and Diu': 'DNHDD',
        'Delhi': 'DL',
        'Jammu and Kashmir': 'JK',
        'Ladakh': 'LA',
        'Lakshadweep': 'LD',
        'Puducherry': 'PY'
    };

    const formatMonth = (month) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthIndex = parseInt(month.split("-")[1], 10) - 1;
        return months[monthIndex];
    };

    return (
        <main>
            <ul className="box-info">
                <li>
                    <i class='bx bxs-school'></i>
                    <span className="text">
                        <h4>Total Schools</h4>
                        <p>{totalSchoolsCount}</p>
                    </span>
                </li>
                <li>
                    <i class='bx bx-test-tube'></i>
                    <span className="text">
                        <h4>Total Labs</h4>
                        <p>{totalLabsCount}</p>
                    </span>
                </li>
                <li>
                    <i class='bx bx-box'></i>
                    <span className="text">
                        <h4>Total Available Equipment </h4>
                        <p>{totalEquipmentCount}</p>
                    </span>
                </li>
                <li>
                    <i class='bx bx-calendar'></i>
                    <span className="text">
                        <h4>Total Sessions Conducted</h4>
                        <p>{totalSessionsCount}</p>
                    </span>
                </li>
            </ul>
            <div className="table-data">
                <div className="order">
                    <div className="head">
                        <h3>Schools Count by State</h3>
                    </div>
                    <table>
                        <tbody>
                            <div style={{ width: '100%', height: 400 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={schoolsData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        {/* <XAxis
                                            dataKey="state"
                                            tick={{ angle: -90, textAnchor: 'middle', dx: -5, dy: 10, fontSize: 12 }}
                                            interval={0}
                                        /> */}
                                        <XAxis
                                            dataKey="state"
                                            tickFormatter={(state) => stateAbbreviations[state] || state}
                                        />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="school_count">
                                            {schoolsData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </tbody>
                    </table>
                </div>
                <div className="order">
                    <div className="head">
                        <h3>Sessions Conducted Per Month</h3>
                    </div>
                    <table>
                        <tbody>
                            <div style={{ width: '100%', height: 400 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={sessionData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="month"
                                            tickFormatter={(month) => formatMonth(month)}
                                        />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="session_count">
                                            {schoolsData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="table-data" style={{ marginBottom: '20px' }}>
                <div className="order">
                    <div className="head">
                        <h3>Lab Equipment Allocation</h3>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Lab Name</th>
                                <th>Equipment Names</th>
                                <th>Equipment Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {labEquipmentData.map((lab, index) => (
                                <tr key={index}>
                                    <td>{lab.lab_name}</td>
                                    <td>{lab.equipment_names.length > 0 ? lab.equipment_names.join(", ") : "No Equipment"}</td>
                                    <td>{lab.equipment_count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="table-data" style={{ marginBottom: '20px' }}>
                <div className="order">
                    <div className="head">
                        <h3>Schools with the most allocated equipment</h3>
                    </div>
                    <table>
                        <tbody>
                            {schoolsWithMostAllocatedEquipment.map((school, index) => (
                                <tr key={index}>
                                    <td>
                                        <p>{school.school_name}</p>
                                    </td>
                                    <td>
                                        <p className="count">{school.total_allocated}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="order">
                    <div className="head">
                        <h3>Labs with the most allocated equipment</h3>
                    </div>
                    <table>
                        <tbody>
                            {labsWithMostAllocatedEquipment.map((lab, index) => (
                                <tr key={index}>
                                    <td>
                                        <p>{lab.lab_name}</p>
                                    </td>
                                    <td>
                                        <p className="count">{lab.total_allocated}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <ul className="box-info">
                <li>
                    <i className='bx bx-store-alt'></i>
                    <span className="text">
                        <h4>Total Artifacts(Docs)</h4>
                        <p>{totalDocsCount}</p>
                    </span>
                </li>
                <li>
                    <i className='bx bx-link' ></i>
                    <span className="text">
                        <h4>Total URLs Shared</h4>
                        <p>{totalUrlsCount}</p>
                    </span>
                </li>
                <li>
                    <i className='bx bx-search'></i>
                    <span className="text">
                        <h4>Searches in {new Date().getFullYear()}</h4>
                        <p>{totalSearches}</p>
                    </span>
                </li>
                <li>
                    <i className='bx bx-search-alt'></i>
                    <span className="text">
                        <h4>Searches in {new Date().toLocaleString('default', { month: 'long' })} '{new Date().getFullYear()}</h4>
                        <p>{currentMonthSearches}</p>
                    </span>
                </li>
            </ul>
            <div className='table-data'>
                <div className='order'>
                    <div className='space-text'>
                        <p>Allocated Space: {(totalAllocatedSpace).toFixed(2)}GB</p>
                        {/* <p>Available Space : {(totalAllocatedSpace - usedSpace).toFixed(2)} GB</p> */}
                    </div>
                    <div className="progress-bar-container">
                        <div className="progress-bar" style={{ width: `${usedSpacePercentage}%` }}>
                            {usedSpacePercentage.toFixed(2)}% used
                        </div>
                    </div>
                </div>
                {/* <div className='order'>
                </div> */}
            </div>
            <div className="table-data">
                <div className="order">
                    <div className="head">
                        <h3>Top 10 Searched Tags</h3>
                    </div>
                    <table>
                        <tbody>
                            {searchedTags.map(tag => (
                                <tr key={tag.tag_name}>
                                    <td>
                                        <p>{tag.tag_name}</p>
                                    </td>
                                    <td>
                                        <p className="count">{tag.search_count}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="order">
                    <div className="head">
                        <h3>Top 10 Contributers</h3>
                    </div>
                    <table>
                        <tbody>
                            {contributors.map(contributor => (
                                <tr key={contributor.owner_author_id}>
                                    <td>
                                        <p>{contributor.owner_author_id}</p>
                                    </td>
                                    <td className='count'>{contributor.doc_count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
};

export default Home;