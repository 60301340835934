import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleAddLabType, fetchExistingLabTypes } from '../ApiHandler/labFunctions';
import usePagination from '../../hooks/usePagination';

const AddLabType = () => {
    const [labType, setLabType] = useState("");
    // const [searchQuery, setSearchQuery] = useState("");
    const [existingLabTypes, setExistingLabTypes] = useState([]);

    useEffect(() => {
        fetchExistingLabTypes(setExistingLabTypes);
    }, []);

    // const filteredExistingLabTypes = existingLabTypes.filter((existingLabType) =>
    //     existingLabType.lab_type?.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    const {
        currentPage,
        entriesPerPage,
        currentEntries,
        handlePageChange,
        handleEntriesChange,
        totalEntries,
        startEntry,
        endEntry,
        totalPages
    } = usePagination(existingLabTypes, 10);

    return (
        <div className="upload-document-container">
            <ToastContainer />
            <header className="upload-document-header">
                <h1>Add Lab Type</h1>
            </header>
            <form className="upload-document-form" onSubmit={(e) => { handleAddLabType(e, labType, setLabType) }}>
                <div className="form-group">
                    <label htmlFor="labTypeInput">Lab Type <m style={{ color: 'red' }}>*</m></label>
                    <input
                        id="labTypeInput"
                        type="text"
                        name="labType"
                        value={labType}
                        onChange={(e) => setLabType(e.target.value)}
                        maxLength={150}
                        placeholder="Enter Lab Type"
                        autoComplete="off"
                        required
                    />
                    <label style={{ color: 'red', fontWeight: '350', fontSize: '12px' }}>Maximum Characters: 150</label>
                </div>
                <div className="form-group">
                    <button type="submit">Submit</button>
                </div>
            </form>
            <div className="artifacts-container" style={{padding: '20px 0 0 0'}}>
                <header className="artifacts-header">
                    <h1>Existing Lab Types</h1>
                </header>
                <div className="artifacts-table-container">
                    <div className='header-select-entries'>
                        <th className='select-entries'>Show
                            <select onChange={handleEntriesChange} value={entriesPerPage}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>entries
                        </th>
                        <th colSpan="4">
                            <div className="table-buttons">
                            </div>
                        </th>
                        {/* <th className='user-search'>
                        <label>Search</label>
                        <input
                            type="text"
                            placeholder="Type here..."
                            className="user-search-bar"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </th> */}
                    </div>
                    <div className="artifacts-table-view">
                        <table className="artifacts-table">
                            <thead>
                                <tr>
                                    <th>Lab Type</th>
                                    <th>Added By</th>
                                    <th>Added On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentEntries.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.lab_type_name}</td>
                                        <td>{item.lab_type_added_by_email}</td>
                                        <td className="date">{item.lab_type_added_on.split('T')[0]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        <p>Showing {startEntry} to {endEntry} of {totalEntries} entries</p>
                        <div className="pagination-buttons">
                            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i + 1}
                                    className={currentPage === i + 1 ? "active" : ""}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="usage-instructions">
                <h2>📢 Usage Instructions</h2>
                <ul>
                    <li><i className='bx bx-paper-plane'></i> This section is used for adding new lab types.</li>
                </ul>
            </div>
        </div>
    );
};

export default AddLabType;