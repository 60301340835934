import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchActiveSchools } from '../ApiHandler/schoolFunctions';
import { fetchLabsForSchool } from '../ApiHandler/labFunctions';
import { fetchActiveEquipments, handleAllocateEquipment } from '../ApiHandler/equipmentFunctions';

const EquipmentAllocation = () => {
    const [equipmentData, setEquipmentData] = useState({
        equipmentId: "",
        equipmentName: "",
        schoolId: "",
        schoolName: "",
        labId: "",
        labName: "",
        allocationDate: "",
        allocatedQuantity: "",
    });

    const [equipments, setEquipments] = useState([]);
    const [schools, setSchools] = useState([]);
    const [labs, setLabs] = useState([]);
    const [availableQuantity, setAvailableQuantity] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "equipmentId") {
            const selectedEquipment = equipments.find(equipment => equipment.id === parseInt(value));
            setAvailableQuantity(selectedEquipment ? selectedEquipment.available_quantity : 0);
            setEquipmentData(prevData => ({
                ...prevData,
                [name]: value,
                equipmentName: selectedEquipment ? selectedEquipment.equipment_name : ""
            }));
        } else if (name === "allocatedQuantity") {
            const quantity = parseInt(value);
            if (quantity > availableQuantity) {
                toast.warning(`The maximum available quantity is ${availableQuantity}`, {
                    position: "top-center"
                });
                setEquipmentData(prevData => ({
                    ...prevData,
                    [name]: availableQuantity
                }));
            } else {
                setEquipmentData(prevData => ({
                    ...prevData,
                    [name]: quantity
                }));
            }
        } else if (name === "schoolId") {
            const selectedSchool = schools.find(school => school.id === parseInt(value));
            setEquipmentData(prevData => ({
                ...prevData,
                [name]: value,
                schoolName: selectedSchool ? selectedSchool.school_name : ""
            }));
            fetchLabsForSchool(value, setLabs);
        } else if (name === "labId") {
            const selectedLab = labs.find(lab => lab.id === parseInt(value));
            setEquipmentData(prevData => ({
                ...prevData,
                [name]: value,
                labName: selectedLab ? selectedLab.lab_name : ""
            }));
        } else {
            setEquipmentData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    useEffect(() => {
        fetchActiveEquipments(setEquipments);
        fetchActiveSchools(setSchools);
    }, []);

    return (
        <div className="upload-document-container">
            <ToastContainer />
            <header className="upload-document-header">
                <h1>Equipment Allocation</h1>
            </header>
            <form className="upload-document-form" onSubmit={(e) => handleAllocateEquipment(e, equipmentData, setEquipmentData)}>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>School Name <m style={{ color: 'red' }}>*</m></label>
                        <select name="schoolId" value={equipmentData.schoolId} onChange={handleChange} required>
                            <option value="">Select</option>
                            {schools.map((school) => (
                                <option key={school.id} value={school.id}>
                                    {school.school_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Lab Name <m style={{ color: 'red' }}>*</m></label>
                        <select name="labId" value={equipmentData.labId} onChange={handleChange} required>
                            <option value="">Select</option>
                            {labs.map((lab) => (
                                <option key={lab.id} value={lab.id}>
                                    {lab.lab_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Equipment Name <m style={{ color: 'red' }}>*</m></label>
                        <select name="equipmentId" value={equipmentData.equipmentId} onChange={handleChange} required>
                            <option value="">Select</option>
                            {equipments.map((equipment) => (
                                <option key={equipment.id} value={equipment.id}>
                                    {equipment.equipment_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Allocation Date <m style={{ color: 'red' }}>*</m></label>
                        <input
                            type="date"
                            name="allocationDate"
                            value={equipmentData.allocationDate}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Available Quantity</label>
                        <input
                            type="number"
                            value={availableQuantity}
                            placeholder="Available Quantity"
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label>Allocation Quantity <m style={{ color: 'red' }}>*</m></label>
                        <input
                            type="number"
                            name="allocatedQuantity"
                            value={equipmentData.allocatedQuantity}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value) && value <= 9999) {
                                    handleChange(e);
                                }
                            }}
                            placeholder="Enter Quantity to Allocate"
                            required
                        />
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default EquipmentAllocation;